<template>
    <div class="frame-box pa-2" :class="!item.daDoc ? `chua-doc` : ``">
        <div class="row align-center justify-space-between">
            <div class="top-left">
                <div class="row align-center">
                    <div class="notify-icon mr-1"></div>
                    <div
                        style="opacity: 0.87; position: relative; top: 1.3px"
                        class="shrink font-medium"
                    >
                        {{ GetTextThoiGian(item.thoiGianThongBao) }}
                    </div>
                </div>
            </div>
            <div class="top-right">
                <i class="mdi mdi-delete-outline color-error" @click="XoaThongBao"></i>
                <!-- <DxDropDownButton
                    :items="['Xóa']"
                    :drop-down-options="{
                        width: 230,
                        wrapperAttr: {
                            class: 'custom-dropdown-button',
                        },
                    }"
                    icon="mdi mdi-dots-vertical"
                    :showArrowIcon="false"
                    @item-click="onItemClick"
                    stylingMode="text"
                /> -->
            </div>
        </div>

        <div class="display-inline"></div>

        <div @click="ClickItemLenh()">
            <div class="row mt-1 pl-1">
                <div
                    class="ellipsis-text grow color-primary font-bold"
                    style="letter-spacing: 0.4px"
                >
                    {{ item.tieuDe }}
                </div>
            </div>

            <div
                class="row align-center justify-space-between color-087 font-thin pl-1"
                style="white-space: break-spaces"
            >
                {{ SetMaxText(item.noiDung, 100) }}
                <!-- <div class="content-left"> -->

                <!-- <div :class="`ellipsis-text ${!item.daDoc ? 'font-bold' : ''}`">
                        {{ item.tieuDe }}
                    </div> -->
                <!-- <div class="ellipsis-text font-thin"> -->
                <!-- </div> -->
                <!-- </div> -->
                <!-- <div class="content-right">
                    <i
                        :class="`mdi mdi-checkbox-blank-circle font-16 ${
                            !item.daDoc ? 'primary--text' : ''
                        }`"
                        :style="!item.daDoc ? '' : 'color:#E0E0E0'"
                    ></i>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { DxDropDownButton } from "devextreme-vue";
import moment from "moment";
export default {
    components: { DxDropDownButton },
    props: {
        item: {
            type: Object,
            default: () => ({
                tenDonVi: "",
                tieuDe: "",
                noiDung: "",
                thoiGianThongBao: new Date(),
            }),
        },
    },
    data() {
        return {};
    },
    methods: {
        SetMaxText(text, maxText) {
            if (text.length < maxText) {
                return text;
            } else {
                return text.substr(0, maxText) + "...";
            }
        },
        //
        GetDay(date) {
            let tmpDate = new Date().setDate(new Date().getDate() - 1);
            if (new Date().toLocaleDateString() == new Date(date).toLocaleDateString()) {
                return "Hôm nay, ";
            } else if (
                new Date(tmpDate).toLocaleDateString() ==
                new Date(date).toLocaleDateString()
            ) {
                return "Hôm qua, ";
            }
            return "";
        },
        GetTextThoiGian(date) {
            return `${this.GetDay(date)}${moment(date).format("HH:mm DD/MM/yyyy")}`;
        },
        ClickItemLenh() {
            if (
                this.item.maHanhDong == this.$t("MaHanhDongThongBao.DOANH_NGHIEP_KY_LENH")
            ) {
                this.$router.push(
                    "/Thong-Bao/Chi-Tiet?idThongBao=" + this.item.idThongBao,
                );
            } else {
                this.XacNhanDaDocThongBao();
            }
        },
        async XacNhanDaDocThongBao() {
            try {
                let rs = await this.$Events.RequestPost({
                    keyRequest: "XacNhanDaDocThongBao",
                    ServicesBE: "ThongBao",
                    url: this.$t("urlThongBao.XacNhanDaDocThongBao"),
                    dataPost: {
                        danhSachIdThongBao: [this.item.idThongBao],
                        tatCaThongBao: false,
                    },
                    msgSuccess: null,
                    msgError: "Đánh dấu đã đọc thông báo thất bại!",
                    msgCatch: "Đánh dấu đã đọc thông báo thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                if (rs.status) {
                    this.XemChiTietLenh();
                }
            } catch (error) {
                console.log("🚀 ~ ThucHienXoaThongBao ~ error:", error);
            }
        },
        XemChiTietLenh() {
            let itemLenh = JSON.parse(this.item.duLieu);

            this.$router.push({
                path: "/tabs/LenhDienTu",
                query: {
                    isChiTiet: true,
                    guidLenh: itemLenh.IdLenh,
                    laLenhGiay: itemLenh.hasOwnProperty("LaLenhDienTu")
                        ? !itemLenh.LaLenhDienTu
                        : false,
                },
            });
        },
        XoaThongBao() {
            this.$emit("XoaMotThongBao", this.item.idThongBao);
        },
    },
    created() {},
    mounted() {},
};
</script>
<style>
.custom-dropdown-button .dx-list .dx-list-item {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}
</style>
<style lang="scss" scoped>
.frame-box {
    border-radius: 8px;
    box-shadow: 0px 3px 8px #ccc;
}
.chua-doc {
    background-color: rgb(3 169 244 / 10%) !important;
}
.notify-icon {
    width: 20px;
    height: 20px;
    // background: green;
    border-radius: 100%;
    background-image: url("../../../assets/images/logo.png");
    background-size: 100%;
}
.ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.display-inline {
    width: 100%;
    height: 1px;
    background: #dadce0;
}
</style>
